import {
  Button,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Text,
  chakra,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import { FC } from "react"
import { BsFillMoonFill, BsFillSunFill, BsTools } from "react-icons/bs"
import { FiMenu } from "react-icons/fi"

import { NextChakraLink } from "@shared/ui/next-chakra-link"

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const Header: FC<MobileProps> = ({ onOpen, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <chakra.header pos={"sticky"} width={"full"} zIndex={3} top={0} bg={useColorModeValue("gray.100", "gray.900")}>
      <Flex
        height={"4rem"}
        px={{ base: 4, md: 4 }}
        mx={"auto"}
        alignItems={"center"}
        justifyContent={{ base: "space-between" }}
        maxW={"8xl"}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant={"outline"}
          aria-label={"open menu"}
          icon={<FiMenu />}
        />

        <Flex alignItems={"center"}>
          <Icon mr={4} fontSize={24} as={BsTools} />
          <NextChakraLink href={"/"} style={{ textDecoration: "none" }}>
            <Text noOfLines={1} fontSize="x-large" fontFamily="monospace" fontWeight="bold">
              Web-toolkit
            </Text>
          </NextChakraLink>
        </Flex>

        <HStack spacing={{ base: "0", md: "6" }}>
          <Button onClick={toggleColorMode}>{colorMode === "light" ? <BsFillMoonFill /> : <BsFillSunFill />}</Button>
        </HStack>
      </Flex>
    </chakra.header>
  )
}

export default Header
