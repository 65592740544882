import { Box, BoxProps, CloseButton, Flex, Icon, Show, Text, useColorModeValue } from "@chakra-ui/react"
import { FC } from "react"
import { IconType } from "react-icons"
import { AiFillFormatPainter } from "react-icons/ai"
import { BsHammer, BsTools } from "react-icons/bs"

import NavItem from "@shared/ui/layout/nav-item"

interface LinkItemProps {
  name: string
  href: string
}

interface LinkGroup {
  name: string
  icon: IconType
  items: Array<LinkItemProps>
}

const formatterItems: Array<LinkItemProps> = [
  { name: "JSON", href: "/json-formatter" },
  { name: "XML", href: "/xml-formatter" },
]

const generators: Array<LinkItemProps> = [{ name: "Image thumbnail", href: "/image-thumbnail" }]

const linkGroups: Array<LinkGroup> = [
  { name: "Generators", icon: BsHammer, items: generators },
  { name: "Formatters", icon: AiFillFormatPainter, items: formatterItems },
]

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const Sidebar: FC<SidebarProps> = ({ onClose, ...rest }) => {
  return (
    <Box
      transition={"3s ease"}
      borderRightWidth={1}
      borderRightColor={useColorModeValue("gray.300", "gray.700")}
      h={"calc(100vh - 8rem)"}
      w={{ base: "full", md: 64 }}
      pos={"fixed"}
      overflow={"auto"}
      {...rest}
    >
      <Show below={"md"}>
        <Flex alignItems={"center"} m={6} justifyContent={"space-between"}>
          <Icon mr={4} fontSize={24} as={BsTools} />
          <Text noOfLines={1} fontSize="x-large" fontFamily="monospace" fontWeight="bold">
            Web-toolkit
          </Text>
          <CloseButton onClick={onClose} />
        </Flex>
      </Show>
      {linkGroups.map((group) => (
        <Box key={group.name}>
          <Flex alignItems="center" mx={8} p={2} color={"cyan.600"}>
            <Icon as={group.icon} mr={2} />
            <Text fontSize={"large"} casing={"uppercase"} as={"b"} fontFamily={"monospace"}>
              {group.name}
            </Text>
          </Flex>
          <Box pl={12}>
            {group.items.map((link) => (
              <NavItem key={link.name} href={link.href} onClick={onClose}>
                <Text fontSize={"small"} fontWeight={"bold"}>
                  {link.name}
                </Text>
              </NavItem>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default Sidebar
