import { Box, Drawer, DrawerContent, VStack, chakra, useDisclosure } from "@chakra-ui/react"
import { FC, ReactNode } from "react"

import Footer from "@shared/ui/layout/footer"
import Header from "@shared/ui/layout/header"
import Sidebar from "@shared/ui/layout/sidebar"

interface LayoutProps {
  children: ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Header onOpen={onOpen} />
      <chakra.main minH={"100vh"} maxW={"8xl"} mx={"auto"}>
        <Box>
          <Drawer
            autoFocus={false}
            returnFocusOnClose={false}
            placement={"left"}
            isOpen={isOpen}
            onClose={onClose}
            onOverlayClick={onClose}
            size={"full"}
          >
            <DrawerContent>
              <Sidebar onClose={onClose} />
            </DrawerContent>
          </Drawer>
          <Sidebar onClose={onClose} display={{ base: "none", md: "block" }} />
          <Box ml={{ base: 0, md: 64 }} p={4}>
            <VStack align={"stretch"} spacing={8}>
              <Box minH={512}>{children}</Box>
              <Footer />
            </VStack>
          </Box>
        </Box>
      </chakra.main>
    </>
  )
}

export default Layout
