import { Box, Container, Hide, Stack, Text, chakra, useColorModeValue } from "@chakra-ui/react"
import React from "react"

import { NextChakraLink } from "@shared/ui/next-chakra-link"

const Footer = () => {
  const currYear = new Date().getFullYear()

  return (
    <chakra.footer>
      <Box borderTopWidth={1} borderColor={useColorModeValue("gray.300", "gray.700")}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={"center"}
          align={"center"}
        >
          <Text fontFamily={"monospace"}>{`© ${currYear} Dmitrii Kulakov`}</Text>
          <Hide below={"md"}>
            <Text fontFamily={"monospace"}>{` | `}</Text>
          </Hide>
          <Text fontFamily={"monospace"}>
            <NextChakraLink href={"https://sq-dev.net/privacy-policy"} target={"_blank"}>
              Privacy Policy
            </NextChakraLink>
          </Text>
        </Container>
      </Box>
    </chakra.footer>
  )
}

export default Footer
