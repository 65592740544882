import { extendTheme } from "@chakra-ui/react"
import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools"

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode("gray.100", "gray.900")(props),
        color: mode("gray.900", "gray.100")(props),
        borderColor: "red",
      },
    }),
  },
  components: {
    Button: {
      variants: {
        solid: (props: StyleFunctionProps) => ({
          bg: mode("gray.300", "gray.600")(props),
        }),
      },
    },
  },
})

export default theme
