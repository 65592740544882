import { Flex, FlexProps, Icon } from "@chakra-ui/react"
import { FC, ReactNode } from "react"
import { IconType } from "react-icons"

import { NextChakraLink } from "@shared/ui/next-chakra-link"

interface NavItemProps extends FlexProps {
  icon?: IconType
  children: ReactNode
  href: string
}

const NavItem: FC<NavItemProps> = ({ icon, children, href, ...rest }) => {
  return (
    <NextChakraLink href={href} style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align={"center"}
        p={2}
        mx={2}
        borderRadius={"lg"}
        role={"group"}
        cursor={"pointer"}
        _hover={{
          bg: "cyan.500",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr={"4"}
            fontSize={"16"}
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </NextChakraLink>
  )
}

export default NavItem
