import { CSSReset, ChakraProvider } from "@chakra-ui/react"
import type { AppProps } from "next/app"
import Head from "next/head"

import theme from "@styles/theme"

import Layout from "@shared/ui/layout"

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <Head key={"common"}>
          <title>Web-toolkit</title>
          <link rel="manifest" href="/site.webmanifest" />
        </Head>
        <CSSReset />
        <Component {...pageProps} />
      </Layout>
    </ChakraProvider>
  )
}

export default MyApp
